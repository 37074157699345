type out = {
      resolved: "404"|"invite"|"call";
      details: {
        inviteCode?: string;
        mediaId?: string;
        fullscreen_call?:boolean;
        roomId?:string;
        initeKey?:string
      };
    };
class exp {
  constructor() {
    this.parse_path();
  }
  public parse_path() {

    let _path = location.href.replace(location.origin, "");
    let _p2 = _path.split("/");
    _p2.shift();
    let out: out = { resolved: "404", details: {} };
    console.log("p2", _p2);
    switch (_p2[0]) {
      case undefined:
        break;
      case "invite":
        out.resolved = "invite";
        out.details.inviteCode = _p2[2];
        switch(_p2[1]){
          case "public":break;
          case "enc":out.details.initeKey=_p2[3];break;
          default:out = { resolved: "404", details: {} };
        }

        console.log(`path invitecode: `, out);
        break;
      case "fullscreen"://  /fullscreen/roomid/videoid
        out.resolved = "call";
        out.details.roomId = _p2[1];
        out.details.mediaId= _p2[2];
        out.details.fullscreen_call=true
        break;
    }
    this.path = out;
    console.log("parsed_path",out)
  }
  //@ts-ignore
  public path:out;
}
export default new exp();
export {exp}