import io from "socket.io-client";
import k26_internal from "./k26_internal";
import { Device } from "mediasoup-client";
import { Transport } from "mediasoup-client/lib/Transport";
import { Producer } from "mediasoup-client/lib/Producer";
import { ConsumerOptions } from "mediasoup-client/lib/Consumer";
enum mediaType {
  audio = "audioType",
  video = "videoType",
  screen = "screenType",
}
console.info("inside newms");
type connect_return = {
  exit: (offline?: Boolean) => void;
  produce: (type: mediaType, deviceId?: string | undefined) => Promise<void>;
  closeProducer: (type: mediaType) => void;
};
let connectionString = `k26-ms://username:usernamesignature@127.0.0.1:2137/roomId#secretey`;
async function connect(
  connectionStrings: string[],
  _key:string,
  remoteVideoEl?: HTMLElement,
  localMediaEl?: HTMLElement,

  _cbs: {
    openVideo?: () => void;
    closeVideo?: () => void;
    openAudio?: () => void;
    closeAudio?: () => void;
    openScreen?: () => void;
    closeScreen?: () => void;
    join?: () => void;
    exit?: () => void;
  } = {}
): Promise<connect_return> {
  console.log("newMS",`got css:`,connectionStrings)
  let _export: connect_return;
  //@ts-ignore
  _export = {};
  let callbacks = {
    openVideo: () => {},
    closeVideo: () => {},
    openAudio: () => {},
    closeAudio: () => {},
    openScreen: () => {},
    closeScreen: () => {},
    join: () => {},
    exit: () => {},
  };
  Object.assign(callbacks, _cbs);
  return new Promise(async (resolve, reject) => {
    let connectionString="";
    let final_server_resolve: Function, final_server_reject: Function;
  let final_server: Promise<string> = new Promise((x, y) => {
    final_server_resolve = x;
    final_server_reject = y;
  });
  let timeout = setTimeout(() => {
    final_server_reject("cannot connect to host (timeout)");
  }, 100000);
  connectionStrings.forEach((host) => {
    fetch(new URL(host.replace("k26-ms://","https://")).origin + "/status")
      .then((e) => {
        e.json()
          .then((x) => {
            console.log(e.status, e, x);
            if (x.ok===true) {
              console.log("newMS",`found mediaServer ${host}`)
              clearTimeout(timeout);
              final_server_resolve(host);
            } else final_server_reject("wrong server response");
          })
          .catch(final_server_reject as any);
      })
      .catch((er) => {
        console.error("hosts", er);
      });
  });
  connectionString = await final_server;
    console.log("newMS", `received url ${connectionString}`);
    let uri = new URL(connectionString.replace("k26-ms:", "https:"));
    console.log("newMS", "parsed", uri);
    if (new URL(connectionString).protocol !== "k26-ms:")
      throw new Error("inavlid protocol");
    let socket = io(uri.origin, {
      auth: {
        token: uri.username

      },
      transports:["websocket"]
    });
    async function req(key: string, data?: any):Promise<any> {
      if (!data) data = {};
      return new Promise((res, rej) => {
        socket.emit(key, data, (out: any) => {
          if (out.error) return rej(out.error);
          res(out);
        });
      });
    }
    let producerLabel = new Map();
    let device: Device;
    let consumers = new Map();
    let producers = new Map();
    socket.on("launch", async (resp) => {
      console.log("newMS", "join", resp);
      callbacks.join();
      let producerTransport: Transport,
        consumerTransport: Transport,
        consumers = new Map();

      try {
        device = new Device();
        await device.load({
          routerRtpCapabilities: resp.routerRtpCaps,
        });

        (() => {
          let _data = resp.rtcTransports;
          {
            const data = _data[0];
            console.log("ms", "createwebrtctransport data:", data);
            producerTransport = device.createSendTransport(data);

            producerTransport.on(
              "connect",
              async function ({ dtlsParameters }, callback, errback) {
                req("connectTransport", {
                    dtlsParameters,
                    transport_id: data.id,
                    cons: false,
                  })
                  .then(callback)
                  .catch(errback);
              }
            );

            producerTransport.on(
              "produce",
              async function ({ kind, rtpParameters }, callback, errback) {
                try {
                  const { producer_id } = await req(
                    "produce",
                    {
                      producerTransportId: producerTransport.id,
                      kind,
                      rtpParameters,
                    }
                  );
                  callback({
                    id: producer_id,
                  });
                } catch (err) {
                  errback(err);
                }
              }
            );

            producerTransport.on("connectionstatechange", function (state) {
              console.log("ms:connectionStateChange", state);
              switch (state) {
                case "connecting":
                  break;

                case "connected":
                  //localVideo.srcObject = stream
                  break;

                case "failed":
                  producerTransport.close();
                  break;
              }
            });
          }

          // init consumerTransport
          {
            const data = _data[1];
            if (data.error) {
              console.error(data.error);
              return;
            }

            // only one needed
            consumerTransport = device.createRecvTransport(data);
            consumerTransport.on(
              "connect",
              function ({ dtlsParameters }, callback, errback) {
                req("connectTransport", {
                    transport_id: consumerTransport.id,
                    dtlsParameters,
                    cons: true,
                  })
                  .then(callback)
                  .catch(errback);
              }
            );

            consumerTransport.on(
              "connectionstatechange",
              async function (state) {
                switch (state) {
                  case "connecting":
                    break;

                  case "connected":
                    break;

                  case "failed":
                    consumerTransport.close();
                    break;
                }
              }
            );
          }
        })();
        socket.on("newProducers", async function (data) {
          console.log("newMS", "new producers", data);
          for (let { producer_id } of data) {
            const { rtpCapabilities } = device;
            const data: ConsumerOptions = await req(
              "consume",
              {
                rtpCapabilities,
                consumerTransportId: consumerTransport.id, // might be
                producerId: producer_id,
              }
            );
            data.producerId = producer_id;

            let consumer = await consumerTransport.consume(data);
            consumers.set(consumer.id, consumer);

            let stream = new MediaStream();
            stream.addTrack(consumer.track);
            let elem;

            if (data.kind === "video") {
              elem = document.createElement("video");
              elem.muted = true;
              elem.srcObject = stream;
              elem.id = consumer.id;
              elem.playsInline = false;
              elem.autoplay = true;
              elem.className = "vid";
              elem.style.maxHeight = "384px";
              elem.style.maxWidth = "384px";
              elem.style.height = "auto";
              remoteVideoEl?.appendChild(elem);
            } else {
              k26_internal.ac
                .createMediaStreamSource(stream)
                .connect(k26_internal.ac.destination);
            }

            await consumer;
          }
        });

        socket.on("disconnect", function () {
          exit(true);
        });
      } catch (error) {
        console.error(error);
        alert(error);
      }

      function exit(offline: Boolean | undefined = false) {
        let clean = function () {
          consumerTransport.close();
          producerTransport.close();
          callbacks.exit();
        };

        if (!offline) {
          req("exitRoom")
            .then((e) => {socket.disconnect();console.log("newMS", "roomclient", e)})
            .catch((e) => console.warn("newMS", "roomclient", e))
            .finally(clean);
        } else {
          clean();
        }
      }
      _export.exit = exit;

      async function produce(
        type: mediaType,
        deviceId: string | undefined = undefined
      ) {
        let audio = type === mediaType.audio;

        if (!device.canProduce("video") && !audio) {
          console.error("newMS:produce", "cannot produce video");
          return;
        }
        if (producerLabel.has(type)) {
          console.log(
            "roomclient",
            "producer already exists for this type " + type
          );
          return;
        }

        let stream: MediaStream;
        try {
          let track;
          let producer: Producer;
          if (type === mediaType.screen) {
            //@ts-ignore
            stream = await navigator.mediaDevices.getDisplayMedia();
            track = stream.getVideoTracks()[0];
            producer = await producerTransport.produce({ track });
          } else {
            if (type === mediaType.video) {
              stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                  width: {
                    min: 640,
                    ideal: 1920,
                  },
                  height: {
                    min: 400,
                    ideal: 1080,
                  },
                  deviceId: deviceId,
                },
              });
              track = stream.getVideoTracks()[0];
              const params = {
                track,
                encodings: [
                  {
                    rid: "r0",
                    maxBitrate: 100000,
                    scalabilityMode: "S1T3",
                  },
                  {
                    rid: "r1",
                    maxBitrate: 300000,
                    scalabilityMode: "S1T3",
                  },
                  {
                    rid: "r2",
                    maxBitrate: 900000,
                    scalabilityMode: "S1T3",
                  },
                ],
                codecOptions: {
                  videoGoogleStartBitrate: 1000,
                },
              };

              producer = await producerTransport.produce(params);
            } else {
              stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                  deviceId: deviceId,
                },
                video: false,
              });
              track = stream.getAudioTracks()[0];
              producer = await producerTransport.produce({ track });
            }
          }
          console.log(
            "roomclient",
            "supportedConstraints",
            navigator.mediaDevices.getSupportedConstraints()
          );
          console.log("roomclient", "producer", producer);

          producers.set(producer.id, producer);

          let elem: HTMLVideoElement;
          if (!audio) {
            elem = document.createElement("video");
            elem.srcObject = stream;
            elem.id = producer.id;
            elem.playsInline = false;
            elem.autoplay = true;
            elem.className = "vid";
            elem.style.maxWidth = "384px";
            elem.style.maxHeight = "284px";
            elem.style.height = "auto";
            localMediaEl?.appendChild(elem);
          }

          producer.on("trackended", () => {
            closeProducer(type);
          });

          producer.on("transportclose", () => {
            console.log("roomclient", "producer transport close");
            if (!audio && elem !== undefined && elem != null) {
              //@ts-ignore
              elem.srcObject.getTracks().forEach(function (track) {
                track.stop();
              });
              //@ts-ignore
              elem.parentNode.removeChild(elem);
            }
            producers.delete(producer.id);
          });

          producer.on("close", () => {
            console.log("roomclient", "closing producer");
            if (!audio) {
              //@ts-ignore
              elem.srcObject.getTracks().forEach(function (track) {
                track.stop();
              });
              //@ts-ignore
              elem.parentNode.removeChild(elem);
            }
            producers.delete(producer.id);
          });

          producerLabel.set(type, producer.id);
          /*
          switch (type) {
            case mediaType.audio:
              this.event(_EVENTS.startAudio);
              break;
            case mediaType.video:
              this.event(_EVENTS.startVideo);
              break;
            case mediaType.screen:
              this.event(_EVENTS.startScreen);
              break;
          } */
        } catch (err) {
          console.log("roomclient", err);
        }
      }

      function closeProducer(type: mediaType) {
        console.log("closeprod", type, mediaType);
        if (!producerLabel.has(type)) {
          console.log(
            "roomclient",
            "there is no producer for this type " + type
          );
          return;
        }
        let producer_id = producerLabel.get(type);
        console.log("roomclient", producer_id);
        req("producerClosed", {
          producer_id,
        });
        producers.get(producer_id).close();
        producers.delete(producer_id);
        producerLabel.delete(type);
        if (type !== mediaType.audio) {
          let elem = <HTMLMediaElement>document.getElementById(producer_id);
          console.log("elem", producer_id, elem);
          //@ts-ignore
          elem.srcObject.getTracks().forEach(function (track) {
            track.stop();
          });
          //@ts-ignore
          elem.parentNode.removeChild(elem);
        }
        console.log(type === mediaType.video);
        /*  switch (type) {
          case mediaType.audio:
            this.event(_EVENTS.stopAudio);
            break;
          case mediaType.video:
            console.log("_event", "stopvideo");
            this.event(_EVENTS.stopVideo);
            break;
          case mediaType.screen:
            this.event(_EVENTS.stopScreen);
            break;
        } */
      }
      _export.produce = produce;
      _export.closeProducer = closeProducer;
      resolve(_export);
    });
  });
}
//connect(connectionString, d, e);
//@ts-ignore
window._connect = connect;
export default connect;
export {connect_return}