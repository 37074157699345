/** @format */

import pwa from "./pwa";
import k26_internal from "./k26_internal";
import router from "./router";
import "./newMediaSoup";
//@ts-ignore
window.__VUE_PROD_DEVTOOLS__ = true;
//@ts-ignore
window.consoleclick=()=>{}
console.log("router", router, router.path);
k26_internal.router = router;
k26_internal.pwa = pwa;

console.log("init", "1");

let mainmain = async () => {
  mainmain = async () => {};
  console.log("init", "9");
  let ac = (k26_internal.ac = new AudioContext());
  k26_internal.msaudio.srcObject = ac.createMediaStreamDestination().stream;
  let hosts_to_try = [
    "https://api.k26.pl",
    "https://127.0.0.1",
    "https://localhost",
    "https://192.168.1.24:443",
  ];
  let final_server_resolve: Function, final_server_reject: Function;
  let final_server: Promise<string> = new Promise((x, y) => {
    final_server_resolve = x;
    final_server_reject = y;
  });
  let timeout = setTimeout(() => {
    final_server_reject("cannot connect to host (timeout)");
  }, 100000);
  hosts_to_try.forEach((host) => {
    fetch(host + "/status")
      .then((e) => {
        e.json()
          .then((x) => {
            console.log(e.status, e, x);
            if (x.ok===true) {
              clearTimeout(timeout);
              final_server_resolve(host);
            } else final_server_reject("wrong server response");
          })
          .catch(final_server_reject as any);
      })
      .catch((er) => {
        console.error("hosts", er);
      });
  });

  try {
    (k26_internal.hosts.api = await final_server),
      await (
        await import("./socket")
      ).default;

    import("./start");
  } catch (e) {
    //@ts-ignore
    document.write(e.message || e);
    console.error(e);
    throw e;
  }
};
k26_internal.logoff = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  location.reload();
};
let _init = () => {
  _init = () => {};
  console.log("init", "8");
  requestAnimationFrame(mainmain);
};

(() => {
  try {
    console.log("init", "2");
    let p = new Promise((resolve) => {
      resolve(true);
    });
    console.log("init", "3");
    localStorage.setItem("debug", "socket.io-*");
    localStorage.getItem("debug") == "socket.io-*" ||
      (() => {
        throw "no localStorage";
      })();
    console.log("init", "4");
    history.state;
    console.log("env", process.env.NODE_ENV);

    (async () => {
      console.log("init", "5");
      await p;
      console.log("init", "6");
      requestAnimationFrame(() => {
        console.log("init", "7");
        if (document.lastChild) {
          _init();
        } else addEventListener("load", _init);
      });
    })();
  } catch (e) {
    try {
      console.error(e);
    } catch (e) {}
    document.write("error: " + e);
  }
})();
