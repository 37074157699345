let _default:{deferredPrompt:Event,registration:ServiceWorkerRegistration}={deferredPrompt:undefined,registration:undefined};

/*
addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  _default.deferredPrompt= e;
  // Update UI notify the user they can install the PWA
  //showInstallPromotion();
  console.log("pwa",`'beforeinstallprompt' event was fired.`);
});
if ('serviceWorker' in navigator) {
  addEventListener('load', function() {
    navigator.serviceWorker.register('/worker.js').then(function(registration) {
      //@ts-ignore
      _default.registration=registration;
      // Registration was successful
      console.log("pwa",'ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log("pwa",'ServiceWorker registration failed: ', err);
    });
  });
} */
export default _default