import ee from "events";
import {notification} from "./commmon"
import {exp} from "./router"
interface int  {
    
    hosts: { api: string,iframe:string };

    pwa:{deferredPrompt:Event,registration:ServiceWorkerRegistration},
    logoff:Function,
    ac:AudioContext,
    ol:Function,
    rc:any,
    events:ee,//
    request:(type:string, data?:any)=>Promise<any>,
    notification:typeof notification,
    router:exp,
    msaudio:HTMLAudioElement
  }
  let m="request used before socket initialization"
  //@ts-ignore
  let internal:int={
    hosts: { api: "",iframe:"https://k26-pl-iframe.pages.dev/" },
    logoff:()=>{},
    ol:()=>{},
    rc:undefined,
    events:new ee(),
    request:()=>Promise.reject((console.error("socket",m),m)),
    notification,
    msaudio:document.createElement("audio")
  };
  //@ts-ignore
  window._k=internal;
export default  new Proxy(internal,{get:(a,x:any)=>{console.log("get",x);return internal[x]},set:(a,x:any,y:any)=>internal[x]=y});
